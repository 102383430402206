:root {
  --black: #1E1E1E;
  --darkgray: #383F53;
  --gray: #7e849c;
  --lightgray: #d9d9d9;
  --white: #EDF2EF;
  --turquoise: #57C4E5;
  --pink: #F97068;
  --lightpink: #D8AAA8;
  --yellow: #FFB800;
}

/* Common style */
html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--darkgray);
  background-color: var(--white);
  margin: 0;
}

section {
  display: flex;
  flex-direction: column;
}

/* Headings */
h2 {
  font-size: 2.3rem;
}

/* Links */
a {
  color: var(--pink);
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a:visited {
  color: var(--lightpink);
}

/* Icons */
.icon {
  vertical-align: text-top;
  padding-top: 2px;
  margin-left: 3px;
  margin-right: 3px;
}

/* Background styles */
.bg-dark {
  background-color: var(--darkgray);
  color: var(--white);
}

.bg-light {
  background-color: var(--white);
  color: var(--darkgray);
}

/* Centered container */
.container {
  width: 90%;
  max-width: 500px;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Intro */
#intro {
  min-height: 100vh;
  margin-top: 0px;
  font-size: 20px;
}

#portrait {
  width: 60%;
  max-width: 350px;
  border-radius: 100%;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}

#top-skills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#top-skills p {
  width: 80px;
  height: 80px;
  margin: 0px;
  border-radius: 100%;
  font-size: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: var(--white);
  font-size: 3rem;
}

/* Work Experience */
#jobs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
}

.job {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--lightgray);
}

.job .details {
  text-align: left;
}

.job .details > * {
  margin: 5px auto;
}

.job .details > h3 {
  margin-top: 0px;
}

.job .details .dates {
  color: var(--gray);
}

.job .details .description {
  padding-left: 0;
}

/* Portfolio */
#portfolio {
  max-width: 800px;
}

#projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.project {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 350px;
  min-height: 390px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  padding: 10px;
}

.project:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

.project img {
  width: 100%;
  aspect-ratio: 1.68/1;
  background-color: white;
  object-fit: cover;
  border-radius: 5px;
}

.project a {
  color: inherit;
}

/* Project Window */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#project-window {
  position: fixed;
  top: 5%;
  z-index: 1001;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

#project-window img {
  width: 95%;
  max-height: 350px;
  object-fit: contain;
  border-radius: 5px;
}

#project-window ul {
  text-align: left;
}

#project-window #close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

/* Contact */
#contact {
  gap: 10px;
}

#contact form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

#contact h1 {
  font-weight: 700;
  color: var(--darkgray);
  margin: 0 auto;
}

#contact input, #contact textarea {
  box-sizing: border-box;
  width: 100%;
  font-family: inherit; 
  font-size: 16px;
  border-radius: 30px;
  background-color: var(--lightgray);
  color: var(--darkgray);
  border-width: 1px;
  border: 1px solid var(--lightgray);
  border-image: none;
  padding: 15px;
  box-shadow: none;
  outline: none;
}

#contact button {
  padding: 10px 20px;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
  background-color: var(--darkgray);
  border: 2px solid var(--darkgray);
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

#contact button:hover {
  background-color: var(--gray);
  border-color: var(--gray);
}

/* Footer */
footer {
  font-size: 0.9rem;
  padding: 1rem 0;
  width: 100%;
}

footer a {
  color: var(--white);
}

footer a:visited {
  color: var(--white);
}

footer nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
  margin: 1rem;
}

footer nav ul li {
  display: block;
}

#copyright {
  font-size: 0.75rem;
}
